
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import MasterLayout from '@/components/MasterLayout.vue';
import ShippingService from '@/services/shipping-service';
import { SetSnackBar } from '@/helpers/SnackbarHelper';
import { Address } from '@/store/models/AddressModel';
import { Customer } from '@/store/models/CustomerModel';
@Component({
  components: { MasterLayout }
})
export default class PickupPackage extends Vue {
  $refs!: {
    DefaultActions: HTMLFormElement;
  };
  /* Properties */
  /* Store Actions */
  /* Watchers */
  /* Data */
  name: string = '';
  trackingNumber: string = '';
  customerName: string = '';
  isPickingUp: boolean = false;
  isLoadingPacklist: boolean = false;
  shouldShowTrackingNumber: boolean = false;
  packlist: {
    PlNumber: string;
    Address: Address;
    TrackingNumber: string;
    HasBeenPickedUp: boolean;
  } = {
    PlNumber: '',
    Address: new Address(),
    TrackingNumber: '',
    HasBeenPickedUp: false
  };
  /* Computed */
  /* Async Functions */
  async pickupPackage() {
    this.isPickingUp = true;
    try {
      await ShippingService.PickupPackage(
        this.$route.params.id,
        this.name,
        this.trackingNumber
      );
      this.LoadPackingList();
    } catch (err) {
      SetSnackBar(
        'Error has occured, please contact techsupport@just1label.com if you are experiencing any issues'
      );
    }
    this.isPickingUp = false;
  }
  /* Utility Functions */
  goToSignUp(): void {
    this.$router.push('/Register');
  }
  goTo(path: string): void {
    this.$router.push(path);
  }
  /* Loaders */
  async LoadPackingList() {
    this.isLoadingPacklist = true;
    try {
      const { data } = await ShippingService.GetPackingList(this.$route.params.id);
      this.packlist = data;
      this.packlist.Address = new Address(data.Address);
      this.customerName = data.CustomerName;
    } catch (err) {
      SetSnackBar(
        'Error has occured, please contact techsupport@just1label.com if you are experiencing any issues'
      );
    }
    this.isLoadingPacklist = false;
  }
  /* Mounted */
  mounted() {
    this.LoadPackingList();
  }
  /* Created */
  /* Emmited Functions */
}
